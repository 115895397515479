/* User Profile */

.user-profile{
  .side-left{
    .card{
      .avatar{
        img{
          display: block;
          width:90px;
          height: 90px;
          @include border-radius(100%);
          margin-left: auto;
          margin-right: auto;
          @extend .mt-4;
          @extend .mb-3;
        }
        .name{
          text-align: center;
          color: theme-color(light);
          font-size: 1.2rem;
          font-weight: 600;
          margin-bottom: 0;
        }
        .designation{
          text-align: center;
          color: color(white);
          font-size: 0.8rem;
          font-weight: 600
        }
        .email{
          text-align: center;
          color: theme-color(white);
          display: block;
        }
        .number{
          display: block;
          text-align: center;
          color: theme-color(white);
        }
      }
      .overview{
        .achivements{
          padding-left: 0px;
          @include display-flex;
          @include justify-content(center);
          li{
            width: 33.333%;
            @include display-flex;
            @include justify-content(center);
            @include align-items(center);
            @include flex-direction(column);
            p{
              text-align: center;
              margin-bottom: 0;
              font-weight: 600;
              font-size: 1.4rem;
              color: theme-color(light);
              &:last-child{
                font-weight: 500;
                font-size: 0.9rem;
              }
            }
          }
        }
        .about-user{
          @extend .mb-4;
          p{
            font-size: 0.8rem;
          }
        }
        .info-links{
          a{
            font-size: 0.8rem;
            display: block;
            margin-bottom: 10px;
            i{
              font-size: 0.8rem;
              margin-right: 10px;
            }
            span{
              text-align: left;
              display: inline-block;
            }
          }
          .website{}
          .social-link{}
        }
      }
    }
  }
  .side-right{

  }
}
